import { CFV_WITH_OPTIONS } from '@/storychief/constants/Constants';

/**
 * Convert custom field values and turn them ready to be used in CustomFields.jsx.
 *
 * @param {object[]} customFields
 * @param {string} customFields.id
 * @param {string} customFields.type
 * @param {string} customFields.name
 * @param {object[]} values Must be raw values directly from GraphQL.
 * @param {string} values.custom_field_id
 * @param {string} values.value
 * @returns {object[]}
 */
export default function getInitialCustomFieldValues(customFields, values) {
  return customFields.map((customField) => {
    if (CFV_WITH_OPTIONS.includes(customField.type)) {
      return {
        key: customField.name,
        value: values?.filter((v) => v.custom_field_id === customField.id) || null,
      };
    }

    return {
      key: customField.name,
      value: values?.find((v) => v.custom_field_id === customField.id)?.value || '',
    };
  });
}
